<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-2">我意外懷孕了</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-3-1">我想要生下來</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="mentalCare-4-1">身心照顧</router-link></li>
              <li class="breadcrumb-item active" aria-current="page"><router-link to="">面對第一次懷孕</router-link></li>
            </ol>
          </nav>
        </section>

        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="text-box">
              <p class="step-text" data-aos="fade-up">ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up" >選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up">為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="select-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜孕程的身心變化｜</h2>
                </div>
                <div class="select-menu">
                  <img class="mb-60" data-aos="fade-up" src='../../assets/image/front/page02_pic2.jpg'>
                  <hr data-aos="fade-up">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3>心情、情緒</h3>
                        <p>有了寶寶，生活中不再只有自己，是甜蜜也是沈重的負荷，你的理想、你的生涯規劃也都因此需要調整，也許轉個彎，也許延長路程，甚至必須改道，必須思考清楚，該如何抉擇才能兼顧你與孩子的未來。</p>
                      </li>
                    </ul>
                    <ul class="select-item container-inner mt-60">
                      <p><img loading="lazy" class="alignnone size-full wp-image-13000"
                          src="https://www.mamaway.com.tw/pregnancy-nursing-breastfeeding/wp-content/uploads/2014/12/20210423_700x366孕期的身體變化.jpg"
                          alt="" width="700" height="366"
                          srcset="https://www.mamaway.com.tw/pregnancy-nursing-breastfeeding/wp-content/uploads/2014/12/20210423_700x366孕期的身體變化.jpg 700w, https://www.mamaway.com.tw/pregnancy-nursing-breastfeeding/wp-content/uploads/2014/12/20210423_700x366孕期的身體變化-300x157.jpg 300w"
                          sizes="(max-width: 700px) 100vw, 700px" /></p>
                      <p>懷孕對女性而言是個很獨特的階段，在這段期間內，妳會感覺到身體每週到每日的變化日漸顯著。此時維持均衡的飲食習慣及從事輕鬆的運動都是十分重要的。</p>
                      <p>這段被賀爾蒙主導的時間裡，妳很可能會變得愛哭、情緒較多變、晨間起床時害喜等讓妳沮喪的情況，但又可能在瞬間變的生氣蓬勃。不論是哪一種情緒，這都是陪伴妳迎接新生命，讓一個家更完整的喜悅過程。</p>
                      <p>個人的懷孕過程都不太相同，從女人轉變成準媽媽、從第一胎到第二胎或更多…在這裡提供40週孕期常會碰到的狀況給準媽咪們參考。</p>

                      <p><span style="color: #3366ff;"><strong>-懷孕初期-</strong></span></p>

                      <p>
                        <strong>￭&nbsp;0-6週<br></strong>40週孕期的倒數從現在開始，此時還沒有明顯的懷孕跡象，體型尚無明顯變化。此時受精卵著床，妳會發覺白帶中有血絲或有點狀出血。有些準媽咪可能會感到下腹疼痛，像月經來潮前的症狀。月經停止，但少數人第一個月尚有少量的月經樣出血。
                      </p>
                      <p>
                        <strong>￭&nbsp;7-8週<br></strong>此階段的乳房變得細嫩、脹大且乳暈顏色變深。因為靜脈的血液供應量增加，血壓會比平常低，造成頭暈情形。此時的子宮約比網球小些，但它會開始從梨子形狀變成蘋果形狀。妳可能會感到腹部絞痛。
                      </p>
                      <p><strong>￭&nbsp;9-11週<br></strong>孕期的荷爾蒙開始大肆影響妳的身體。此時妳的皮膚狀態會開始改變、牙齦浮腫、刷牙時牙齦易出血。甲狀腺可能會有腫大的情形出現。</p>
                      <p><strong>￭&nbsp;12週<br></strong>妳的子宮已增大突出至骨盆腔，因此可從下腹部摸到子宮。心臟加速提供更多血液到子宮，因此妳看起來紅光滿面，充滿懷孕光芒。</p>
                      <p><strong>￭&nbsp;13週<br></strong>此時荷爾蒙的影響漸小，妳會發現子宮像一個小凸點，並上移到腹部。</p>
                      <p><span style="color: #3366ff;"><strong>-懷孕中期-</strong></span></p>
                      <p><strong>￭&nbsp;14-15週<br></strong>此時子宮的大小約像一顆葡萄柚。妊娠紋開始出現。噁心的感覺可能會開始消失。</p>
                      <p>
                        <strong>￭&nbsp;16-19週<br></strong>妳開始感覺到胎動，有如氣在冒泡或喝水後腸胃蠕動的感覺。懷孕血量增加，妳會變得易流鼻血，有時會有靜脈曲張的現象。妳的下腹隆起已較明顯，到目前為止妳大約增加了4至5公斤體重。乳房及乳頭的腫脹越來越明顯，有人會覺得疼痛。由於子宮的膨大，有人也會有下腹疼痛的感覺，如果疼痛一直持續，則需找醫師檢查。妳的皮下脂肪增厚，腹部明顯突出，一定得穿孕婦裝了。
                      </p>
                      <p>
                        <strong>￭&nbsp;20-21週<br></strong>妳現在的子宮體積約一個嬰兒頭部的大小，子宮底的高度到達肚臍處。這時因為子宮的增大，身體的重心起了變化，突出的腹部使妳的重心往前移，為了保持平衡不得不挺起肚子走路。
                      </p>
                      <p>
                        <strong>￭&nbsp;22-24週<br></strong>現在胎動會較頻繁而且明顯，有時會有少量稀薄的乳汁分泌。雀斑會更明顯，這是因荷爾蒙的變化使肌膚發黑產生斑點。肌膚易變油且易引起過敏反應。子宮的位置約在肚臍上3.5公分至5公分處。
                      </p>
                      <p><strong>￭&nbsp;25-26週<br></strong>此時子宮約是一顆足球的大小。妊娠紋到處可見。妳會開始感覺到寶寶強烈的胎動。子宮的位置約是在肚臍和肋骨之間。</p>
                      <p><span style="color: #3366ff;"><strong>-懷孕後期-</strong></span></p>
                      <p><strong>￭&nbsp;27-28週<br></strong>這兩週妳的體重開始持續增加直到生產。腹部摸起來較硬，但乳房變的柔軟，可能會分泌乳汁。</p>
                      <p><strong>￭&nbsp;29-30週<br></strong>這時妳的腹部隨寶寶的增大而迅速變大，可能會發生小腿痙攣與腳踝輕度水腫的現象。</p>
                      <p>
                        <strong>￭&nbsp;31-32週<br></strong>這兩週妳所感受到的胎動又更明顯了，寶寶的一舉一動幾乎都是與妳息息相關。<br>由於子宮底一直往上升到橫隔膜，有時妳的呼吸會受影響而有呼不過氣的感覺。
                      </p>
                      <p>
                        <strong>￭&nbsp;33-34週<br></strong>妳的體重目前為止應增加10公斤至13公斤。子宮底是在肚臍以上12、3公分處，因子宮日漸膨脹造成後期懷孕的不舒服日益明顯，如反胃、胸悶、易心悸、疲倦、呼吸困難等。由於胎頭正逐漸下降，這些不舒服的現象以後會獲得改善。
                      </p>
                      <p>
                        <strong>￭&nbsp;35-36週<br></strong>這兩週結束後胎兒已往下降，胎位也已固定了。腹壁與子宮壁已變得較薄，寶寶的手肘、小腳和頭部有時會清楚地從妳的腹部突顯出來。妳會常感疲勞，背部痠疼與骨盆腔不適仍持續。
                      </p>
                      <p><strong>￭&nbsp;37-38週<br></strong>妳覺得不舒服，因位寶寶的頭部頂住了陰道口。</p>
                      <p>
                        <strong>￭&nbsp;39-40週<br></strong>子宮頸及陰道也變軟，準備寶寶的出生。不規則陣痛、浮腫、靜脈曲張及痔瘡更加明顯。寶寶隨時會出生，但是不用擔心，只有5%的寶寶是在預產期那天出生的。
                      </p>
                      <p><span style="color: #000000;"><strong>-我的身體怎麼了-</strong></span></p>
                      <p>懷孕幾乎會改變妳身體的每一個部分，從心裡到生理，從頭到腳。妳很難不去發現到這些變化！</p>
                      <p><strong><span style="color: #3366ff;">乳房的變化</span></strong></p>
                      <p>
                        受孕激素影響，乳房在懷孕4～6周後開始增大并變得更加敏感。重量的增加和賀爾蒙的雙重作用，拉長乳房的韌帶和纖維組織，因此乳房有下垂趨勢。乳頭、乳暈顏色加深，乳頭明顯突出，乳頭周圍出現些小結節，甚至乳房有刺痛、膨脹和搔癢感，偶而還可擠出少量乳汁，這些都是準備幫寶寶製造奶水所造成的現象。此時要選擇專用的內衣好好的保護乳房。
                      </p>
                      <p><strong><span style="color: #3366ff;">指甲的變化</span></strong></p>
                      <p>孕期間身體的營養多運往子宮供給寶寶發展，所以不難發現以往光滑亮麗的指甲失去了光澤，同時也變得容易斷裂，此時最好的照顧方式就是剪短它！</p>
                      <p><span style="color: #3366ff;"><strong>皮膚的變化</strong></span></p>
                      <p>
                        有些孕婦臉上會長雀斑或長痘痘，懷孕期的皮膚變差變乾燥。孕期間很可能會發現腹壁、大腿等處皮膚可見長5~6cm、寬約0.5cm的不規則平行裂紋，這些紋呈紫紅或淡紅色。有30%的孕婦臉上或脖子上可能出現棕色孕斑，還有些孕婦臉上原有斑點的顏色加深，一般在分娩或斷奶幾個月後自行消失。多食用含維生素a豐富的食物能減輕症狀。此時要注意防曬，日曬可能會加重暗沉或斑點的情形！
                      </p>
                      <p><span style="color: #3366ff;"><strong>睡眠的變化</strong></span></p>
                      <p>懷孕期間獲得良好的睡眠是十分重要的一件事！在午後打個小盹可能對很多上班族孕婦是一種很難達到的渴望，因此不妨改變生活型態，晚上提早1小時上床睡覺，讓一天的疲勞及壓力提早獲得舒緩、解放！</p>
                      <p>
                        夜晚的頻尿也是引響孕婦睡眠的兇手之一，因此試著在睡眠前不要攝入過多的水分，上床前也養成上廁所的習慣，盡妳的一切可能讓睡眠不要中斷！有時妳會發現，挺著一個大肚子想要找到一個完美的睡眠姿勢比登天還難，此時建議採取側睡的方式，可以在腿中夾住一個枕頭，讓枕頭幫妳分攤肚子的重量。
                      </p>
                      <p><span style="text-decoration: underline; color: #0000ff;"><span
                            style="text-decoration: underline;">懷孕會讓妳在一天內遭遇情緒低落而大哭、孕吐或是心情愉快感到紅光滿面</span></span></p>
                      <p><span style="color: #3366ff;"><strong>牙齒的變化</strong></span></p>
                      <p>懷孕期間，荷爾蒙分泌會有所改變，如果妳沒徹底清潔口腔，牙齦 (牙肉)
                        就容易對牙菌膜中的細菌所分泌的毒素產生過敏反應，變得紅腫和容易出血，這現象稱為「妊娠期牙齦炎」。建議起床、睡前用含氟化物牙膏刷牙，並用牙線徹底清除牙菌膜就可以預防「妊娠期牙齦炎」，亦可促進輕微的牙齦發炎自然痊癒。
                      </p>
                      <p>定期看牙醫有助維持口腔健康</p>
                      <p><span style="color: #3366ff;"><strong>情緒變化</strong></span></p>
                      <p>
                        女性從懷孕起，激素水平就會發生改變，特別是在懷孕早期出現噁心、嘔吐、身體不適等現象，也容易引起情緒波動，使孕婦情緒不穩定，依賴性強，甚至出現神經質。孕婦的情緒變化又會引起激素水平變化和血液成份變化，對孕婦自身和胎兒產生不利影響。有關專家還提出孕婦的心理健康和寶寶智商有一定關係的觀點。美國哥倫比亞大學研究發現，孕婦在懷孕期間，若心情常焦慮、憂鬱，生下的小孩比較容易有氣喘問題；其實不只氣喘，孕婦心理對胎兒情緒也有影響。因此，孕婦應該及時調整心理，放鬆心情，凡事豁達，不斤斤計較，不順心的事不鑽牛角尖。
                      </p>
                      <p><strong><span style="color: #3366ff;">頭髮變化</span></strong></p>
                      <p>
                        在懷孕期間體內激素的變化會增加頭髮生長。許多孕婦發現自己的髮質較粗。有時候，一般通常不會有毛髮生長的地方，如臉部，胸部和手臂，也會長出毛髮。這種新的毛髮生長被稱為多毛症，可能是因為體內的荷爾蒙改變。這樣的狀況會到產後6個月內恢復正常。
                      </p>
                      <p><strong><span style="color: #3366ff;">產前抑鬱</span></strong></p>
                      <p>
                        雖然懷孕對於大部分的夫婦是一件充滿喜悅的事，但是對大部分的媽媽來說，也是一個很大的壓力。準媽媽會對許多事情感到憂心，例如：寶寶是不是正常？如果家族裡面有先天性異常的例子，壓力通常會更大。該如何減輕心中的不安呢？
                      </p>
                      <p>
                        ★利用產檢時間，與醫生溝通解決心中的疑慮<br>★與長輩或過來人溝通獲得資訊與經驗談，來應付未來和如何做個好媽媽<br>★適度的運動來減輕壓力，鬆弛肌肉，像散步、柔軟體操等<br>★先生的協助及幫忙對於幫妳度過懷孕這一段時期非常重要
                      </p>
                      <p>懷孕是一件辛苦又開心的事情，孕期間碰到生理或是心理上的不適時，都應該要盡量尋求外力的協助來改善，有健康的身心，寶寶才能在安穩的環境中長大！</p>
                      <li data-aos="fade-up">
                        <p>參考資料：<a class="fs-5 text-danger" href=" https://www.mamaway.com.tw/pregnancy-nursing-breastfeeding/658/pregnancy/" target="_blank">Mamaway《孕期的身體變化》</a></p>
                      </li>
                      </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  components: {
    Consult
  }
}
</script>
